import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import backIcon from '../../assets/icons/backicon.svg'

export default function CheckForwarding() {

    const navigate = useNavigate()

    return (
        <>
            <Box sx={{
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '1%',
                    fontSize: 14,
                }
            }} >
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    color: '#006838',
                    fontSize: '24px'
                }}
                >เอกสารการส่งต่อ</Box>
                <div style={{ width: '100%', height: '100vh' }}>
                    <iframe height="800px" width="100%" src="https://health-atm.numedapp.com/res/pdf/mock-refer.pdf" />
                </div>
            </Box>
        </>
    )
}