import { Outlet } from 'react-router-dom'

import React from 'react'
import { Box } from '@mui/material'

export default function AuthLayout() {
  return (
    <Box>
        <Outlet/>
    </Box>
  )
}

