// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HealthStyle_btGreen__1gDQM {
    background-color: #E1F4EB;
    border: 1px solid #006838;
    border-radius: 10px;

    font-weight: 700;
    color: #006838;
    text-align: center;
    padding: 10px;
}

.HealthStyle_btGay__ysmMu {
    background-color: none;
    border: none;
    border-radius: 10px;

    font-weight: 700;
    color: #263238;
    text-align: center;
    padding: 10px;
}

.HealthStyle_btGay__ysmMu , .HealthStyle_btGreen__1gDQM:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/page/healthWithIoT/HealthStyle.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;;IAEnB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;;IAEnB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".btGreen {\n    background-color: #E1F4EB;\n    border: 1px solid #006838;\n    border-radius: 10px;\n\n    font-weight: 700;\n    color: #006838;\n    text-align: center;\n    padding: 10px;\n}\n\n.btGay {\n    background-color: none;\n    border: none;\n    border-radius: 10px;\n\n    font-weight: 700;\n    color: #263238;\n    text-align: center;\n    padding: 10px;\n}\n\n.btGay , .btGreen:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btGreen": `HealthStyle_btGreen__1gDQM`,
	"btGay": `HealthStyle_btGay__ysmMu`
};
export default ___CSS_LOADER_EXPORT___;
