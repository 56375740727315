export const environment = {

    // PROD (production : true)
    // production: true,
    // endpoint_domain: '',
    // endpoint_socket: '',
    // endpoint_domain_r12: ''


    // UAT (production : false)
    production: false,
    endpoint_domain: 'https://api-healthatm-med2go.numeddatacenter.com/dashboard-api',
    endpoint_socket: 'http://localhost:30051',
    endpoint_api: 'http://localhost:30049',
    endpoint_domain_r12: 'https://api-r12network-uat.numeddatacenter.com/dashboard-api',
    endpoint_api_worker: 'https://workerhealth.ddc.moph.go.th'

    // local
    // production: false,
    // endpoint_domain: 'https://api-healthatm-med2go.numeddatacenter.com/dashboard-api',
    // endpoint_socket: 'http://localhost:30050',
    // endpoint_api: 'http://localhost:30049',
    // endpoint_domain_r12: 'https://api-r12network-uat.numeddatacenter.com/dashboard-api'

}