import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { notifyError, notifyInfo, notifySuccess } from '../components/NotifyToast'
import Loader from '../components/Loader'
import { login, user_data } from '../store/authSlice'
import * as api from '../service/api'
import { jwtDecode } from 'jwt-decode';

export default function LoginThaiD() {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let refreshToken = searchParams.get('RefreshToken')
        let accessToken = searchParams.get('AccessToken')

        console.log(accessToken, refreshToken)

        if (accessToken) {
            getParameter(accessToken, refreshToken)
        }
    }, [])

    const getParameter = async (access, refresh) => {
        if (refresh) {
            const expire = jwtDecode(access);
            console.log(expire)

            dispatch(login({ "ExpireInTimestamp": expire }))
            localStorage.setItem('access_token', access)
            localStorage.setItem("refresh_token", refresh)

            await profile()
        } else {
            notifyError('ไม่ตอบสนอง กรุณาติดต่อเจ้าหน้าที่')
        }

    }

    const profile = async () => {
        setLoading(true)
        let res = await api.profile()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                dispatch(user_data(res['data']['data']))
                navigate('/consent')
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <>
            <Loader loading={loading} />
        </>
    )
}
