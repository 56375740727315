import React from "react";
import styles from './ModalAlert.module.css'
import exclamation from '../../assets/icons/exclamation.svg'


export default function ModalAlert({ onClose, detail }) {

    return (
        <div className={styles.overlay}>
            <div className={styles.modalController}>
                <div className={styles.contentModal}>
                    <img src={exclamation} />
                    <div className={styles.detail}>{detail}</div>
                    <button className={styles.button} onClick={onClose}>ยืนยัน</button>
                </div>
            </div>
        </div>
    )
}