import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from '../../service/api'
import { Box, Button, FormControl, MenuItem, Pagination, Select, Stack } from "@mui/material";
import backIcon from '../../assets/icons/backicon.svg'
import { notifyError } from '../../components/NotifyToast'
import DataTable from "react-data-table-component";

const customStyles = {
    headCells: {
        style: {
            // override the cell padding for head cells
            display: 'flex',
            justifyContent: 'center',
            color: '#006838',
            fontSize: 18,
            '@media (max-width: 800px)': {
                fontSize: 14,
            }
        }
    },
    cells: {
        style: {
            // override the cell padding for data cells
            display: 'flex',
            justifyContent: 'center',
            color: '#747676',
            fontSize: 16,
            '@media (max-width: 800px)': {
                fontSize: 12,
            }
        }
    }
};

export default function AppointmentList() {

    const navigate = useNavigate()
    const location = useLocation()
    const [pid, setPid] = useState(new URLSearchParams(location.search).get('PID'))

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [appointment, setAppoinment] = useState([])
    const [appointmentAll, setAppoinmentAll] = useState([])
    const [showList, setShowList] = useState([])
    const [numPage, setNumPage] = useState(5)
    const [maxPage, setMaxPage] = useState(1)
    const [page, setPage] = useState(1)


    useEffect(() => {
        setDate('')
    }, [hospital])

    useEffect(() => {
        if (hospital === '' || hospital === ' ') {
            if (date === '' || date === ' ') {
                findAppointment()
            } else {
                filterDateData()
            }
        } else {
            if (date === '' || date === ' ') {
                filterHospitalData()
            } else {
                filterDateData()
            }
        }
    }, [date, hospital])

    useEffect(() => {
        // findAppointment()
        setDate('')
        setHospital('')

        let start = page * numPage - numPage
        setShowList(appointmentAll.slice(start, start + numPage))
        setMaxPage((Math.ceil(appointmentAll.length / numPage)))
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(appointment.slice(start, start + numPage))
    }, [page, appointment])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const findAppointment = async () => {
        let res = await api.findAppointment(pid)
        if (res) {
            if (res['data']['status'] === true) {
                console.log(res['data']['data'])

                setAppoinmentAll(res['data']['data'])
                setAppoinment(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))

                filterHospitalOption(res['data']['data'])

                //option date
                const List = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        List.push(res['data']['data'][i]['appoint_date'])
                    }
                }
                const unique = [...new Map(List.map((m) => [m, m])).values()] //กรองชื่อซ้ำ
                setOptionDate(unique)
            } else {
                if (res['data']['message'] === "ไม่พบข้อมูล") {
                    console.log(res['data']['message'])
                } else {
                    notifyError(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const filterHospitalOption = (data) => {
        let option = []
        for (let i = 0; i < data.length; i++) {
            if (data[i]) {
                option.push({
                    key: data[i]['hos_id'],
                    value: data[i]['hos_name']
                })
            }
        }
        const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
        setOptionHospital(unique)
        console.log(unique)
    }

    const filterHospitalData = () => {
        const List = []
        for (let i = 0; i < appointmentAll.length; i++) {
            if (appointmentAll[i]['hos_id'] === hospital) {
                List.push(appointmentAll[i])
            }
        }
        let start = page * numPage - numPage
        setShowList(List.slice(start, start + numPage))
        setPage(1)
        setMaxPage((Math.ceil(List.length / numPage)))

        const Date = []
        for (let i = 0; i < List.length; i++) {
            if (List[i]) {
                Date.push(List[i]['appoint_date'])
            }
        }
        const unique = [...new Map(Date.map((m) => [m, m])).values()] //กรองชื่อซ้ำ
        setOptionDate(unique)
    }

    const filterDateData = () => {
        const List = []
        for (let i = 0; i < appointmentAll.length; i++) {
            if (appointmentAll[i]['appoint_date'] === date) {
                List.push(appointmentAll[i])
            }
        }
        setShowList(List)
        setPage(1)
        setMaxPage((Math.ceil(List.length / numPage)))
    }


    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const columns = [
        {
            name: '#',
            selector: (i, idx) => <div>{idx + 1}</div>,
            width: '100px',
        },
        {
            name: 'ชื่อโรงพยาบาล',
            selector: i => <div>{i['hos_name']}</div>,
            minWidth: '200px',
            wrap: true
        },
        {
            name: 'วันที่นัดหมาย',
            selector: i => <div>{convertDate(i['appoint_date'])}</div>,
            minWidth: '180px'
        },
        {
            name: 'เวลานัดหมาย',
            selector: i => <div>{i['appoint_time']}</div>,
            minWidth: '150px'
        },
        {
            name: 'หัวข้อนัดหมาย',
            selector: i => <div>{i['appoint_note'] || '-'}</div>,
            minWidth: '200px',
            wrap: true
        },
        {
            name: 'แผนกที่นัดหมาย',
            selector: i => <div>{i['clinic_name']}</div>,
            minWidth: '200px',
            wrap: true
        },
        {
            name: 'แพทย์',
            selector: i => <div>{i['doctor_name']}</div>,
            minWidth: '250px',
            wrap: true
        },
    ]

    return (
        <>
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>

                <Box sx={{
                    textAlign: 'center',
                    color: '#006838',
                    fontSize: '24px'
                }}
                >รายการนัดหมาย</Box>

                <Box sx={{
                    border: 1,
                    borderColor: '#E1E3E9',
                    borderRadius: '10px',
                    bgcolor: '#FFF'
                }}>
                    <Box sx={{
                        color: '#006838',
                        fontWeight: 700,
                        backgroundColor: '#E1F4EB',
                        borderBottom: '1px solid #E1E3E9',
                        borderRadius: '10px',
                        padding: '10px 20px'
                    }}>ค้นหา</Box>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        padding: '20px',
                        '@media (max-width: 1023px)': {
                            gridTemplateColumns: '1fr',
                            padding: '15px'
                        },
                        '@media (max-width: 800px)': {
                            justifyItems: 'center',
                            padding: '10px'
                        },
                    }}>
                        <FormControl sx={{ width: '90%', color: '#006838' }} size="small">
                            <div style={{ fontWeight: 700 }}>โรงพยาบาลที่เข้ารับบริการ</div>
                            <Select
                                value={hospital || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setHospital(value)
                                }}
                            >
                                <MenuItem value=" ">ทั้งหมด</MenuItem>
                                {optionHospital.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['key']}
                                    >
                                        {item['value']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: '90%', color: '#006838' }} size="small">
                            <div style={{ fontWeight: 700 }}>ค้นหาวันที่</div>
                            <Select
                                value={date || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setDate(value)
                                }}
                            >
                                <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                                {optionDate.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item}
                                    >
                                        {convertDate(item)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {showList ?
                    <Box sx={{
                        border: 1,
                        borderColor: '#E1E3E9',
                        borderRadius: '10px',
                        overflowX: "auto"
                    }}>
                        <DataTable
                            columns={columns}
                            data={showList}
                            customStyles={customStyles}
                            noDataComponent={<div style={{ margin: '10%' }}>ไม่พบข้อมูล</div>}
                        />

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: 2
                        }}>
                            <Box>
                                <FormControl fullWidth>
                                    <Select
                                        value={numPage}
                                        onChange={(e) => {
                                            const {
                                                target: { value },
                                            } = e;
                                            setNumPage(value)
                                        }}
                                        size='small'
                                        sx={{ width: '100px', fontSize: '14px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Stack spacing={2}>
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={handlePageChange}
                                    shape="rounded"
                                    color="primary"
                                />
                            </Stack>
                        </Box>
                    </Box>
                    : null
                }
            </Box>
        </>
    )
}