import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import backIcon from '../../assets/icons/backicon.svg'
import { useNavigate } from 'react-router-dom'
import * as api from '../../service/api'
import { notifyError, notifyWarning } from '../../components/NotifyToast'
import Loader from '../../components/Loader'

export default function ViewXrayhistory() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [urlData, setUrlData] = useState(null)

    useEffect(() => {
        findDicomViewer()
    }, [])

    const findDicomViewer = async () => {
        setLoading(true)
        let res = await api.findDicomViewer()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setUrlData(res['data']['data']['url'])
            } else {
                notifyWarning(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>

                <Box sx={{
                    textAlign: 'center',
                    color: '#006838',
                    fontSize: '24px'
                }}
                >x-ray</Box>

                <Box sx={{height: '80vh'}}>
                    <iframe src={urlData} height="80%" width="100%" scrolling="no" />
                </Box>
            </Box>
        </>
    )
}
