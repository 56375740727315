import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import authReducer from './authSlice';

const appReducer = combineReducers({
    auth: authReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        storage.removeItem('persist:root')
        state = {};
    }
    return appReducer(state, action);
};

export default rootReducer;