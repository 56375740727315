import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import backIcon from '../../assets/icons/backicon.svg'
import { useLocation, useNavigate } from "react-router-dom";
import * as api from '../../service/api'
import { notifyError } from '../../components/NotifyToast'
import machine from '../../assets/images/machine.svg'

export default function OffSiteLab() {

    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    // const [pid, setPid] = useState()
    const [labResults, setLabResults] = useState([])

    useEffect(() => {
        // console.log(new URLSearchParams(location.search).get('PID'))
        findBloodTestResult(new URLSearchParams(location.search).get('PID'))
    }, [])

    const findBloodTestResult = async (pid) => {
        setLoading(true)
        let res = await api.findBloodTestResult(pid)
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setLabResults(res['data']['data'])
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const translateHeader = (name) => {
        if (`${name}`.toLowerCase() === 'CPR'.toLowerCase()) {
            return 'การทดสอบภาวะการอักเสบในร่างกาย (CRP)'
        } else if (`${name}`.toLowerCase() === 'HbA1c'.toLowerCase()) {
            return 'การตรวจฮีโมโกลบิน เอ วัน ซี (HbA1c)'
        } else if (`${name}`.toLowerCase() === 'Lipid Panel'.toLowerCase()) {
            return 'ระดับไขมันในเส้นเลือด (Lipid Panel)'
        } else if (`${name}`.toLowerCase() === 'ACR'.toLowerCase()) {
            return 'การตรวจหาอัลบูมินในปัสสาวะ (ACR)'
        } else {
            return name
        }
    }
    const translate = (name) => {
        if (`${name}`.toLowerCase() === 'Chol'.toLowerCase()) {
            return 'คอเลสเตอรอล (Chol)'
        } else if (`${name}`.toLowerCase() === 'LDL'.toLowerCase()) {
            return 'คอเลสเตอรอลชนิดไม่ดี (LDL)'
        } else if (`${name}`.toLowerCase() === 'HDL'.toLowerCase()) {
            return 'คอเลสเตอรอลชนิดดี (HDL)'
        } else if (`${name}`.toLowerCase() === 'Trig'.toLowerCase()) {
            return 'ไตรกลีเซอไรด์ (Trig)'
        } else {
            return name
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '2%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    color: '#006838',
                    fontSize: '24px'
                }}
                >ผลตรวจแลปนอกสถานที่</Box>
                {labResults.length > 0 ?
                    <Box sx={{
                        display: 'flex',
                        gridGap: 15,
                        overflow: "hidden",
                        overflowX: "scroll",
                    }}>
                        {labResults.map((i, idx) => (
                            <Box sx={{
                                minWidth: '450px',
                                backgroundColor: '#E1F4EB',
                                borderRadius: 3,
                                padding: 3,
                                maxWidth: '300px',
                                marginBottom: '1rem',
                                '@media (max-width: 1023px)': {
                                    padding: 2,
                                    minWidth: '350px',
                                },
                                '@media (max-width: 800px)': {
                                    padding: 1,
                                    minWidth: '300px',
                                }
                            }} key={idx}>

                                <Box sx={{
                                    fontWeight: 700,
                                    color: '#006838',
                                    fontSize: 20,
                                    '@media (max-width: 1023px)': {
                                        fontSize: 18
                                    }
                                }}>{convertDate(i['TestDate'])}</Box>

                                <Box sx={{
                                    display: 'grid',
                                    gridGap: 15,
                                    marginBottom: 1,
                                    '@media (max-width: 1023px)': {
                                        padding: 1,
                                        gridGap: 15,
                                    },
                                    '@media (max-width: 800px)': {
                                        padding: 1,
                                        gridGap: 8,
                                    }
                                }}>
                                    <Box sx={{
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: 2,
                                        padding: 2
                                    }}>
                                        <Box sx={{
                                            color: '#006838',
                                            fontSize: 24,
                                            fontWeight: 500,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gridGap: 10,
                                            '@media (max-width: 1023px)': {
                                                fontSize: 20
                                            }
                                        }}>
                                            <img src={machine} />
                                            <div>ผลตรวจเลือด</div>
                                        </Box>

                                        {i['TestResults'].map((item, index) => (
                                            <Box key={index}>
                                                <Box sx={{
                                                    padding: '5px 30px',
                                                    '@media (max-width: 1023px)': {
                                                        padding: '5px 15px',
                                                    }
                                                }}>
                                                    <Box sx={{
                                                        fontWeight: 700,
                                                        color: '#006838',
                                                        fontSize: 22,
                                                        '@media (max-width: 1023px)': {
                                                            fontSize: 18
                                                        }
                                                    }}>{translateHeader(item['TestName'])}</Box>
                                                    {item['TestResults'].map((i, idx) => (
                                                        <Box key={idx} sx={{
                                                            display: 'flex',
                                                            gridGap: 10,
                                                            padding: '0 20px',
                                                            '@media (max-width: 1023px)': {
                                                                display: 'grid',
                                                                gridGap: 1
                                                            }
                                                        }}>
                                                            <div style={{ fontWeight: 700, color: '#006838' }}>{translate(i['ResultName']) + '  '}</div>
                                                            <div>{i['ResultValue'] + ' ' + i['ResultUnit']}</div>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        ))}

                                    </Box>

                                </Box>
                            </Box>
                        ))}

                    </Box>
                    :
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10%',
                        fontSize: '20px',
                        '@media (max-width: 800px)': {
                            marginTop: '10%',
                            fontSize: '16px',
                        }
                    }}>ไม่พบข้อมูล</Box>
                }
            </Box>
        </>
    )
}