import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { Box } from "@mui/material";
import backIcon from '../../assets/icons/backicon.svg'
import { useNavigate } from "react-router-dom";

export default function CheckPermissions() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [userData, setUserData] = useState(null)

    useEffect(() => {
        const user = localStorage.getItem('userData')
        const data = JSON.parse(user)
        setUserData(data)
        console.log(data)
    }, [])

    return (
        <div>
            {/* <Loader loading={loading} /> */}
            {userData &&
                <Box sx={{
                    color: '#263238',
                    display: 'grid',
                    gridGap: 20,
                    fontSize: 18,
                    margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                    '@media (max-width: 1023px)': {
                        gridGap: '3%',
                        fontSize: 16,
                    },
                    '@media (max-width: 800px)': {
                        gridGap: '1%',
                        fontSize: 14,
                    }
                }}>
                    <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                        <img src={backIcon} />
                        <div>ย้อนกลับ</div>
                    </Box>
                    <Box sx={{
                        textAlign: 'center',
                        color: '#006838',
                        fontSize: 24,
                        '@media (max-width: 800px)': {
                            fontSize: 18,
                        }
                    }}
                    >ตรวจสอบสิทธิการรักษา</Box>
                    <Box sx={{
                        display: 'grid',
                        border: 1,
                        borderRadius: 2,
                        borderColor: '#E1E3E9',
                        padding: '20px',
                        '@media (max-width: 1023px)': {
                            padding: '15px'
                        },
                        bgcolor: '#FFF'
                    }}>
                        <Box>
                            <Box sx={{fontWeight: 700, color: '#006838' }}>สิทธิการรักษา</Box>
                            <Box sx={{
                                margin: '10px 50px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 20px',
                                }
                            }}>
                                <Box sx={{fontWeight: 700, color: '#006838' }}>สิทธิการรักษาหลัก</Box>
                                <Box>{userData['personal']['right']['main_inscl'] || '-'}</Box>
                                <Box sx={{fontWeight: 700, color: '#006838' }}>สิทธิการรักษารอง</Box>
                                <Box>{userData['personal']['right']['sub_inscl'] || '-'}</Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{fontWeight: 700, color: '#006838' }}>โรงพยาบาลหลัก</Box>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                margin: '10px 50px'
                            }}>
                                <Box>
                                    <Box sx={{fontWeight: 700, color: '#006838' }}>รหัสสถานพยาบาล</Box>
                                    <Box>{userData['personal']['right']['hospital_main']['hospital_id'] || '-'}</Box>
                                </Box>
                                <Box>
                                    <Box sx={{fontWeight: 700, color: '#006838' }}>ชื่อสถานพยาบาล</Box>
                                    <Box>{userData['personal']['right']['hospital_main']['hospital_name'] || '-'}</Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{fontWeight: 700, color: '#006838' }}>โรงพยาบาลย่อย</Box>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                margin: '10px 50px'
                            }}>
                                <Box>
                                    <Box sx={{fontWeight: 700, color: '#006838' }}>รหัสสถานพยาบาล</Box>
                                    <Box>{userData['personal']['right']['hospital_sub']['hospital_id'] || '-'}</Box>
                                </Box>
                                <Box>
                                    <Box sx={{fontWeight: 700, color: '#006838' }}>ชื่อสถานพยาบาล</Box>
                                    <Box>{userData['personal']['right']['hospital_sub']['hospital_name'] || '-'}</Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            }
        </div>
    )
}