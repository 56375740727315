import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

import logo from '../assets/images/logo.svg'
import thaiID from '../assets/icons/thaiID.svg'

import qrcode from '../assets/images/thaiDQRcode.png'
import Loader from "../components/Loader";

import * as api from '../service/api'
import { notifyError, notifyInfo } from "../components/NotifyToast";

export default function GuideService() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const clickQRcodeThaiD = () => {
        getQrCode()
    }

    const getQrCode = async () => {
        setLoading(true)
        let res = await api.getQrCode()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                window.open(res['data']['url'], '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }


    return (
        <div className='bg__health__atm'>
            <Loader loading={loading} />
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <Box sx={{
                        height: 'auto',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E9',
                        boxShadow: '5px 5px 20px #E1E3E9',
                        borderRadius: '20px',
                        padding: '40px',
                        margin: '20px',
                        display: 'grid',
                        justifyItems: 'center',
                        gridGap: '20px',
                        '@media (max-width: 600px)': {
                            padding: '20px 10px',
                            gridGap: '20px',
                        }
                    }}>
                        <Box sx={{
                            width: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            gridGap: 20,
                            '@media (max-width: 600px)': {
                                width: '100px'
                            }
                        }}>
                            <img style={{ width: '50%' }} src={logo} />
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            '@media (max-width: 800px)': {
                                display: 'grid'
                            }
                        }}>
                            <Box sx={{ display: 'grid', alignContent: 'center' }}>
                                <Box sx={{ display: 'grid', gridGap: '20px' }}>
                                    <Typography sx={{
                                        fontSize: 18,
                                        fontWeight: 700,
                                        color: '#006838',
                                        display: 'grid',
                                        justifyItems: 'center',
                                        '@media (max-width: 800px)': {
                                            fontSize: 16,
                                        }
                                    }}>กดปุ่ม</Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: '10px', display: 'flex', gridGap: '15px' }}
                                            onClick={() => { clickQRcodeThaiD() }}
                                        >
                                            <img className="imgIcon" src={thaiID} />
                                            <Box sx={{
                                                fontSize: 18,
                                                '@media (max-width: 800px)': {
                                                    fontSize: 16,
                                                }
                                            }}>สแกน QR Code ด้วยแอป ThaiD</Box>
                                        </Button>
                                    </Box>
                                    <Box sx={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        color: '#006838',
                                        display: 'grid',
                                        justifyItems: 'center',
                                        '@media (max-width: 800px)': {
                                            fontSize: 16,
                                        }
                                    }}>
                                        <div>เพื่อเข้ารับบริการ เอทีเอ็มสุขภาพ</div>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                borderLeft: '2px solid #E1E3E9',
                                margin: '0 30px',
                                '@media (max-width: 800px)': {
                                    borderTop: '2px solid #E1E3E9',
                                    margin: '30px 0',
                                }
                            }}></Box>

                            <Box sx={{ display: 'grid', gridGap: '20px' }}>
                                <Box sx={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    color: '#006838',
                                    display: 'grid',
                                    justifyItems: 'center',
                                    '@media (max-width: 800px)': {
                                        fontSize: 16
                                    }
                                }}>
                                    <div>หากยังไม่มี ThaiD สแกน QR Code นี้</div>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <img src={qrcode} style={{ maxWidth: '200px' }} />
                                </Box>
                                <Box sx={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    color: '#006838',
                                    display: 'grid',
                                    justifyItems: 'center',
                                    '@media (max-width: 1000px)': {
                                        fontSize: 16
                                    }
                                }}>
                                    <div>เพื่อดาวน์โหลดแอป ThaiD</div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}