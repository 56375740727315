
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import backIcon from '../../assets/icons/backicon.svg'
import { useNavigate } from "react-router-dom";

import refer from '../../assets/files/refer.pdf'
import resive from '../../assets/files/resive.pdf'

export default function Refer() {

    const navigate = useNavigate()

    const [value, setValue] = useState('0')
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    let referDoc = refer;
    let resiveDoc = resive;

    return (
        <>
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '1%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>

                <TabContext value={value}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab sx={{
                                fontSize: 18,
                                fontWeight: 600,
                                '@media (max-width: 800px)': {
                                    fontSize: 14,
                                }
                            }}
                                label={'ส่งต่อผู้ป่วย'}
                                value={'0'} />
                            <Tab sx={{
                                fontSize: 18,
                                fontWeight: 600,
                                '@media (max-width: 800px)': {
                                    fontSize: 14,
                                }
                            }}
                                label={'รับผู้ป่วย'}
                                value={'1'} />
                        </TabList>
                    </Box>

                    <TabPanel value={'0'} sx={{padding: 0}}>
                        <div style={{ width: '100%', height: '100vh' }}>
                            <iframe height="900px" width="100%" src={referDoc} />
                        </div>
                    </TabPanel>

                    <TabPanel value={'1'} sx={{padding: 0}}>
                        <div style={{ width: '100%', height: '100vh' }}>
                            <iframe height="900px" width="100%" src={resiveDoc} />
                        </div>
                    </TabPanel>

                </TabContext>

            </Box>

        </>
    )
}