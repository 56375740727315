import { Box, Button, Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from '../service/api'
import Loader from '../components/Loader'
import { notifyError } from "../components/NotifyToast";
import { useSelector } from "react-redux";

export default function Consent() {

    const location = useLocation()
    const navigate = useNavigate()
    const userData = useSelector((state) => state.auth.user_data)
    const [loading, setLoading] = useState(false)

    const [check, setCheck] = useState(true)

    const [dataAll, setDataAll] = useState(null)

    useEffect(() => {
        setDataAll(userData)
        console.log(userData)
    }, [])

    const consent = async (pid, name) => {
        setLoading(true)
        let res = await api.confirmConsent(pid, name)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                navigate('/mainMenu')
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleChange = (event) => {
        setCheck(event)
    }

    const clickConfirmConsent = async (pid, name) => {
        console.log(pid, name)
        consent(pid, name)
        // navigate('/mainMenu')
    }

    return (
        <div className='bg__health__atm'>
            {dataAll &&
                <>
                    <Loader loading={loading} />
                    <Box sx={{
                        color: '#263238',
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            maxWidth: '600px',
                            height: 'auto',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #E1E3E9',
                            borderRadius: '20px',
                            padding: '40px',
                            margin: '20px',
                            display: 'grid',
                            justifyItems: 'center',
                            gridGap: '20px',
                            '@media (max-width: 500px)': {
                                padding: '10px',
                                gridGap: '10px',
                            }
                        }}>
                            <Box sx={{
                                fontSize: '28px',
                                fontWeight: 600,
                                color: '#006838',
                                display: 'grid',
                                justifyItems: 'center',
                                '@media (max-width: 600px)': {
                                    fontSize: '16px',
                                }
                            }}>
                                <div>การยินยอมให้เปิดเผยข้อมูลด้านสุขภาพ</div>
                                <div>ของบุคคลทางอิเล็กทรอนิกส์</div>
                            </Box>
                            <Box sx={{
                                wordBreak: 'break-word',
                                border: '1px solid #E1E3E9',
                                borderRadius: '20px',
                                padding: '20px',
                                fontSize: '18px',
                                '@media (max-width: 700px)': {
                                    padding: '15px',
                                    fontSize: '16px',
                                }
                            }}>
                                <div>1. ข้าพเจ้า {dataAll['pname']} {dataAll['fname'] + ' ' + dataAll['lname']} เลขประจำตัวประชาชน {dataAll['pid']} ในฐานะ ประชาชนทั่วไป/ผู้ป่วย</div>
                                <div>
                                    2. ข้าพเจ้า ได้รับการอธิบายจากสถานพยาบาล ให้ทราบถึงวัตถุประสงค์ในโครงการ ฯ
                                    ข้อจำกัดด้านเทคโนโลยีและอิเล็กทรอนิกส์รวมถึงมาตรการปกป้องที่เหมาะสมเพื่อ
                                    คุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคล
                                </div>
                                <div>3. ข้าพเจ้า ยินยอมเข้าสู่การให้ข้อมูลภายใต้โครงการฯ โดยยินยอมเปิดเผยข้อมูล/ส่งข้อมูล
                                    ทางอิเล็กทรอนิกส์ (สำเนาข้อมูล) เพื่อการวินิจฉัยโรคทางการแพทย์ การให้บริการด้าน
                                    สุขภาพหรือด้านสังคม การรักษาทางการแพทย์ การจัดการด้านสุขภาพ การป้องกันด้าน
                                    สุขภาพจากโรคติดต่ออันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร
                                    เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของข้าพเจ้าแก่ผู้ประกอบ
                                    วิชาชีพทางการแพทย์และสาธารณสุข
                                </div>
                                <div>4. หากหน่วยงานและรพ. ดังกล่าวนำข้อมูลสุขภาพส่วนบุคคลของข้าพเจ้าไปใช้เพื่อ
                                    วัตถุประสงค์อื่นของโครงการฯ ต้องได้รับคำยินยอมจากข้าพเจ้า เว้นแต่การเปิดเผย
                                    ตามที่กฎหมายบัญญัติหรือมีคำสั่งศาลให้เปิดเผย
                                </div>
                                <div>5. ข้าพเจ้า สามารถจะถอนความยินยอมเมื่อใดก็ได้ การถอนความยินยอมย่อมไม่ส่งผล
                                    กระทบต่อการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคล
                                    ได้ให้ความยินยอมไปแล้ว โดยการยกเลิกความยินยอมต้องมีหลักฐานเป็นลายลักษณ์อักษร
                                </div>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={check}
                                    onChange={(e) => { handleChange(e.target.checked) }}
                                />
                                <Box sx={{
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 16,
                                    }
                                }}>ข้าพเจ้ายินยอมให้บุคลากรทางการแพทย์เข้าถึงข้อมูลทางการแพทย์ได้ในภายใน 24 ชั่วโมงเท่านั้น</Box>
                            </Box>
                            <Box sx={{ display: 'flex', gridGap: 20 }}>
                                <Button sx={{
                                    width: '120px'
                                }} size="large" variant="outlined"
                                    onClick={() => { console.log('ddddd') }}
                                >ยกเลิก</Button>
                                <Button
                                    sx={{
                                        width: '120px'
                                    }} size="large" variant="contained"
                                    disabled={!check}
                                    onClick={() => {
                                        clickConfirmConsent(dataAll['pid'], dataAll['pname'] + dataAll['fname'] + ' ' + dataAll['lname'])
                                    }}
                                >ยืนยัน</Button>
                            </Box>

                        </Box>
                    </Box>
                </>
            }
        </div>
    )
}