import React, { useEffect, useRef, useState } from "react"
import { Alert, Box, Button, Typography } from "@mui/material"

import medical from '../assets/icons/medicaldata.svg'
import healthcheck from '../assets/icons/healthcheck.svg'
import healthstation from '../assets/icons/healthstation.svg'
import lab from '../assets/icons/lab.svg'
import calendar from '../assets/icons/calendar.svg'
import call from '../assets/icons/consult.svg'
import docRefer from '../assets/icons/doc.svg'
import xray from '../assets/icons/xray.svg'

import medicalG from '../assets/icons/medicaldataG.svg'
import healthcheckG from '../assets/icons/healthcheckG.svg'
import labG from '../assets/icons/labG.svg'
import healthstationG from '../assets/icons/healthstationG.svg'
import calendarG from '../assets/icons/calendarG.svg'
import callG from '../assets/icons/consultG.svg'
import docReferG from '../assets/icons/docG.svg'
import xrayG from '../assets/icons/xrayG.svg'

import { useNavigate } from "react-router-dom"
import styles from './Login.module.css'
import ModalAlert from '../components/modal/ModalAlert'
import { useDispatch, useSelector } from "react-redux"

import {logout} from '../store/authSlice'


export default function MainMenu() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const userData = useSelector((state) => state.auth.user_data)

    let buttonKey = useRef(null);

    const menu = [
        {
            key: '1',
            value: 'ตรวจสอบประวัติ \n จากทุกโรงพยาบาล',
            image: medical,
            imageG: medicalG,
            status: true
        },
        {
            key: '2',
            value: 'ผลตรวจสุขภาพ \n ประจำปี',
            image: healthcheck,
            imageG: healthcheckG,
            status: true
        },
        {
            key: '3',
            value: 'ผลตรวจแลป \n นอกสถานที่',
            image: lab,
            imageG: labG,
            status: true
        },
        {
            key: '4',
            value: 'คัดกรองสุขภาพ \n ความดันและน้ำตาล',
            image: healthstation,
            imageG: healthstationG,
            status: true
        },
        {
            key: '8',
            value: 'ดูประวัติ X-RAY',
            image: xray,
            imageG: xrayG,
            status: true
        },
        {
            key: '5',
            value: 'รายการนัดหมาย',
            image: calendar,
            imageG: calendarG,
            status: true
        },
        {
            key: '6',
            value: 'ปรึกษา',
            image: call,
            imageG: callG,
            status: true
        },
        // {
        //     key: '7',
        //     value: 'เอกสารการส่งต่อ',
        //     image: docRefer,
        //     imageG: docReferG,
        //     status: true
        // },
    ]

    const clickMenu = (item) => {
        let cid = userData.pid
        

        if (item['key'] === '1') { //ประวัติการรักษา ในโรงพยาบาลเครือข่าย
            buttonKey.current = item['key']
            navigate(`/medicalInformationNew?PID=${cid}`)
        } else if (item['key'] === '2') { //ผลตรวจสุขภาพ ประจำปี
            buttonKey.current = item['key']
            navigate('/healthResultsAnnual')
        } else if (item['key'] === '3') { //ผลตรวจแลป นอกสถานที่
            buttonKey.current = item['key']
            navigate(`/offsiteLabResults?PID=${cid}`)
        } else if (item['key'] === '4') { //คัดกรองสุขภาพ ความดันและน้ำตาล
            buttonKey.current = item['key']
            navigate(`/healthHistory?PID=${cid}`)
        } else if (item['key'] === '5') { //รายการนัดหมาย
            navigate(`/appointmentList?PID=${cid}`)
        } else if (item['key'] === '6') { //ปรึกษาหมอ
            navigate('/consult')
        } else if (item['key'] === '7') { //เอกสารการส่งต่อ
            navigate('/refer')
        } else if (item['key'] === '8') { //ดูประวัติ X-RAY
            navigate('/xray')
        } 

    }

    return (
        <div className='bg__health__atm'>
            {openModal ?
                <ModalAlert
                    detail={'กรุณาเสียบบัตรประชาชน'}
                    onClose={() => { setOpenModal(false) }}
                />
                : null
            }

            <Box sx={{
                height: '100vh',
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        gridGap: '20px',
                        '@media (max-width: 1024px)': {
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: '15px'
                        }
                    }}>
                        {menu.map((i, idx) => (
                            <React.Fragment key={idx}>
                                {i['status'] ?
                                    <Box className={styles.boxMenu}
                                        key={idx}
                                        onClick={() => { clickMenu(i) }}
                                    >
                                        <Box>
                                            <img src={i['image']} style={{ width: '40px', height: '40px' }} />
                                        </Box>
                                        <Box sx={{
                                            color: i['status'] ? '#006838' : '#747676',
                                            fontSize: '22px',
                                            display: 'grid',
                                            justifyItems: 'center',
                                            '@media (max-width: 700px)': {
                                                fontSize: '16px'
                                            }
                                        }}>
                                            {i['value'].split('\n').map((item, index) =>
                                                <div key={index}>{item}</div>
                                            )}
                                        </Box>
                                    </Box>
                                    :
                                    <Box className={styles.boxMenuDis}
                                        key={idx}
                                    >
                                        <Box>
                                            <img src={i['imageG']} style={{ width: '40px', height: '40px' }} />
                                        </Box>
                                        <Box sx={{
                                            color: i['status'] ? '#006838' : '#747676',
                                            fontSize: '22px',
                                            display: 'grid',
                                            justifyItems: 'center',
                                            '@media (max-width: 700px)': {
                                                fontSize: '16px'
                                            }
                                        }}>
                                            {i['value'].split('\n').map((item, index) =>
                                                <div key={index}>{item}</div>
                                            )}
                                        </Box>
                                    </Box>
                                }
                            </React.Fragment>

                        ))}
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px'
                    }}>
                        <Button variant="contained" style={{ width: '300px', fontSize: '20px' }}
                            onClick={() => {
                                dispatch(logout())
                                localStorage.clear()
                                navigate('/')
                            }}
                        >สิ้นสุด</Button>
                    </Box>
                </Box>
            </Box>

        </div>
    )
}