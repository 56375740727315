// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalAlert_overlay__\\+Qkdm {
    z-index: 1;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #0000008f; */
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
}

.ModalAlert_modalController__GvrJ0 {
    width: auto;
    height: auto;
    background: #ffffff;
    border-radius: 30px;
}

.ModalAlert_contentModal__MbdTM {
    padding: 50px;
    display: grid;
    justify-items: center;
    grid-gap: 20px;
}

.ModalAlert_contentModal__MbdTM img {
    width: 100px;
    height: 100px;
}

.ModalAlert_detail__gERf2 {
    font-size: 40px;
    color: #006838;
}

.ModalAlert_button__fpWxz {
    width: 450px;
    height: 60px;
    background-color: #006838;
    border: 1px solid #006838;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 20px;
}

@media screen and (max-width: 800px) {
    .ModalAlert_contentModal__MbdTM {
        padding: 20px;
        grid-gap: 10px;
    }
    
    .ModalAlert_contentModal__MbdTM img {
        width: 70px;
        height: 70px;
    }

    .ModalAlert_detail__gERf2 {
        font-size: 25px;
    }
    
    .ModalAlert_button__fpWxz {
        width: 280px;
        height: 40px;
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/modal/ModalAlert.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,YAAY;IACZ,WAAW;IACX,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,mCAA2B;YAA3B,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;QACb,cAAc;IAClB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":[".overlay {\n    z-index: 1;\n    position: fixed;\n    height: 100%;\n    width: 100%;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    /* background: #0000008f; */\n    backdrop-filter: blur(30px);\n}\n\n.modalController {\n    width: auto;\n    height: auto;\n    background: #ffffff;\n    border-radius: 30px;\n}\n\n.contentModal {\n    padding: 50px;\n    display: grid;\n    justify-items: center;\n    grid-gap: 20px;\n}\n\n.contentModal img {\n    width: 100px;\n    height: 100px;\n}\n\n.detail {\n    font-size: 40px;\n    color: #006838;\n}\n\n.button {\n    width: 450px;\n    height: 60px;\n    background-color: #006838;\n    border: 1px solid #006838;\n    border-radius: 8px;\n    color: #FFFFFF;\n    font-size: 20px;\n}\n\n@media screen and (max-width: 800px) {\n    .contentModal {\n        padding: 20px;\n        grid-gap: 10px;\n    }\n    \n    .contentModal img {\n        width: 70px;\n        height: 70px;\n    }\n\n    .detail {\n        font-size: 25px;\n    }\n    \n    .button {\n        width: 280px;\n        height: 40px;\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `ModalAlert_overlay__+Qkdm`,
	"modalController": `ModalAlert_modalController__GvrJ0`,
	"contentModal": `ModalAlert_contentModal__MbdTM`,
	"detail": `ModalAlert_detail__gERf2`,
	"button": `ModalAlert_button__fpWxz`
};
export default ___CSS_LOADER_EXPORT___;
