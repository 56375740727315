import { Box } from "@mui/material";
import React from "react";

import sugar from '../../assets/images/machineBloodSuger.svg'
import blood from '../../assets/images/machineBlood.svg'
import weigth from '../../assets/images/machineWeight.svg'
import oxygen from '../../assets/images/machineOxygen.svg'

export default function VitalSigns() {

    return (
        <div style={{height: '100vh'}}>
            <iframe
                src="https://rasppi4-health-gateways.tunnel-dev.com/static/health.html"
                height="100%"
                width="100%"
            ></iframe>
            
            {/* <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '2%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    border: 1,
                    borderRadius: 2,
                    borderColor: '#E1E3E9',
                    padding: '20px',
                    gridGap: 20,
                    '@media (max-width: 1023px)': {
                        gridTemplateColumns: '1fr',
                        padding: '15px'
                    },
                    '@media (max-width: 800px)': {
                        justifyItems: 'center',
                        padding: '10px'
                    },
                    bgcolor: '#FFF',
                }}>
                    <Box sx={{
                        width: '100%',
                        display: 'grid',
                        gridGap: 20
                    }}>
                        <Box>
                            <Box sx={{
                                background: '#E1F4EB',
                                border: '1px solid #006838',
                                borderRadius: '20px 20px 0 0'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gridGap: 10,
                                    fontSize: 20,
                                    fontWeight: 700,
                                    color: '#006838',
                                    margin: '10px 20px'
                                }}>
                                    <img src={sugar} />
                                    <div>เครื่องวัดระดับน้ำตาลในเลือด</div>
                                </Box>
                            </Box>
                            <Box sx={{
                                background: '#FFFFFF',
                                border: '1px solid #E1E3E9',
                                borderRadius: '0 0 20px 20px'
                            }}>
                                <Box sx={{
                                    margin: '20px 30px'
                                }}>
                                    <div style={{ color: '#006838' }}>ระดับน้ำตาลในเลือด (Blood sugar)</div>
                                    <div>- mg/dL</div>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{
                                background: '#E1F4EB',
                                border: '1px solid #006838',
                                borderRadius: '20px 20px 0 0'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gridGap: 10,
                                    fontSize: 20,
                                    fontWeight: 700,
                                    color: '#006838',
                                    margin: '10px 20px'
                                }}>
                                    <img src={weigth} />
                                    <div>เครื่องชั่งน้ำหนัก</div>
                                </Box>
                            </Box>
                            <Box sx={{
                                background: '#FFFFFF',
                                border: '1px solid #E1E3E9',
                                borderRadius: '0 0 20px 20px'
                            }}>
                                <Box sx={{
                                    margin: '20px 30px'
                                }}>
                                    <div style={{ color: '#006838' }}>น้ำหนัก (Weight)</div>
                                    <div>- kg</div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: 'block'
                    }}>
                        <Box sx={{
                            background: '#E1F4EB',
                            border: '1px solid #006838',
                            borderRadius: '20px 20px 0 0'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gridGap: 10,
                                fontSize: 20,
                                fontWeight: 700,
                                color: '#006838',
                                margin: '10px 20px'
                            }}>
                                <img src={blood} />
                                <div>เครื่องวัดความดันโลหิต</div>
                            </Box>
                        </Box>
                        <Box sx={{
                            background: '#FFFFFF',
                            border: '1px solid #E1E3E9',
                            borderRadius: '0 0 20px 20px'
                        }}>
                            <Box sx={{
                                margin: '20px 30px'
                            }}>
                                <div style={{ color: '#006838' }}>ค่าความดันโลหิตตัวบน (SYS)</div>
                                <div>- mmHg</div>
                            </Box>
                            <Box sx={{
                                margin: '20px 30px'
                            }}>
                                <div style={{ color: '#006838' }}>ค่าความดันโลหิตตัวล่าง (DIA)</div>
                                <div>- mmHg</div>
                            </Box>
                            <Box sx={{
                                margin: '20px 30px'
                            }}>
                                <div style={{ color: '#006838' }}>อัตราการเต้นของหัวใจ (BPM)</div>
                                <div>- /min</div>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: 'block'
                    }}>
                        <Box sx={{
                            background: '#E1F4EB',
                            border: '1px solid #006838',
                            borderRadius: '20px 20px 0 0'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gridGap: 10,
                                fontSize: 20,
                                fontWeight: 700,
                                color: '#006838',
                                margin: '10px 20px'
                            }}>
                                <img src={oxygen} />
                                <div>เครื่องวัดออกซิเจนในเลือด</div>
                            </Box>
                        </Box>
                        <Box sx={{
                            background: '#FFFFFF',
                            border: '1px solid #E1E3E9',
                            borderRadius: '0 0 20px 20px'
                        }}>
                            <Box sx={{
                                margin: '20px 30px'
                            }}>
                                <div style={{ color: '#006838' }}>ค่าออกซิเจนในเลือด (SpO2)</div>
                                <div>- %</div>
                            </Box>
                            <Box sx={{
                                margin: '20px 30px'
                            }}>
                                <div style={{ color: '#006838' }}>อัตราการเต้นของหัวใจ (BPM)</div>
                                <div>- /min</div>
                            </Box>
                            <Box sx={{
                                margin: '20px 30px'
                            }}>
                                <div style={{ color: '#006838' }}>การไหลเวียนของเลือด (PI)</div>
                                <div>-%</div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box> */}
        </div>
    )
}