import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import backIcon from '../../assets/icons/backicon.svg'
import * as api from '../../service/api'
import { notifyError } from '../../components/NotifyToast'

export default function HealthResultsAnnual() {

    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [year, setYear] = useState(new Date().getFullYear())
    const [optionYear, setOptionYear] = useState(Array.from(new Array(10), (val, index) => year - index))

    const [url, setUrl] = useState('')
    const [loadUrl, setLoadUrl] = useState(false)
    const [noData, setNodata] = useState('กำลังเชื่อมต่อ')

    useEffect(() => {
        // getIndividualSummary()
        getHealthCheck()
    }, [year])

    const getHealthCheck = async () => {
        setLoading(true)
        let res = await api.getHealthCheck(year)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setLoadUrl(true)
                console.log(res['data'])
                setUrl(res['data']['url'])
                setLoadUrl(false)
            } else if (res['data']['code'] === 404) {
                setNodata('ไม่พบข้อมูล')
            } else {
                notifyError(res['data']['message_th'])
            }
        } else {
            setNodata('ไม่ตอบสนอง')
            notifyError('ไม่ตอบสนอง')
        }
    }

    // const getIndividualSummary = async () => {
    //     setLoading(true)
    //     // let res = await api.getIndividualSummary(year)
    //     setLoading(false)
    //     if (res) {
    //         if (res['data']['code'] === 200) {
    //             setLoadUrl(true)
    //             setTimeout(() => {
    //                 // console.log(res['data']['url'])
    //                 setUrl(res['data']['url'])
    //             }, 1000)
    //             setLoadUrl(false)
    //         } else if (res['data']['code'] === 404) {
    //             setNodata('ไม่พบข้อมูล')
    //         } else {
    //             notifyError(res['data']['message_th'])
    //         }
    //     } else {
    //         setNodata('ไม่ตอบสนอง')
    //         notifyError('ไม่ตอบสนอง')
    //     }
    // }

    return (
        <>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '2%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    color: '#006838',
                    fontSize: '24px'
                }}
                >ผลการตรวจสุขภาพประจำปี</Box>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    border: 1,
                    borderRadius: 2,
                    borderColor: '#E1E3E9',
                    padding: '20px',
                    '@media (max-width: 1023px)': {
                        gridTemplateColumns: '1fr',
                        padding: '15px'
                    },
                    '@media (max-width: 800px)': {
                        justifyItems: 'center',
                        padding: '10px'
                    },
                    bgcolor: '#FFF',
                }}>
                    <FormControl sx={{ width: '90%', color: '#006838' }} size="small">
                        <div style={{ fontWeight: 700 }}>เลือกปีที่ต้องการ</div>
                        <Select
                            value={year}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setYear(value)
                                setNodata('กำลังเชื่อมต่อ')
                                setUrl('')
                            }}
                        >
                            {optionYear.map((year, index) => (
                                <MenuItem key={`year${index}`} value={year}>{year + 543}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: '100%', height: '70vh' }}>
                    {url ?
                        <iframe src={url} height="800px" width="100%" />
                        :
                        <>
                            {loadUrl ?
                                <Loader loading={loadUrl} />
                                :
                                <Box sx={{
                                    fontSize: 20,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}>{noData}</Box>
                            }
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}