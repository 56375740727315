import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AccessDenied() {

    const navigate = useNavigate()
    const [counter, setCounter] = useState(3)

    useEffect(() => {
        CountNumber()
    }, [counter]);

    const CountNumber = () => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
        if (counter === 0) {
            navigate('/')
        }
    }

    return (
        <div className="bg__health__atm">
            <Box sx={{
                color: '#263238',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    boxShadow: '5px 5px 20px #E1E3E9',
                    borderRadius: '20px',
                    padding: '40px',
                    display: 'grid',
                    justifyItems: 'center',
                    margin: '20px',
                    '@media (max-width: 800px)': {
                        padding: '20px'
                    }
                }}>
                    <Box sx={{
                        color: '#006838',
                        fontSize: '40px',
                        '@media (max-width: 800px)': {
                            fontSize: '28px',
                        }
                    }}>ปฏิเสธการเข้าใช้งาน</Box>
                    <Box sx={{
                        fontSize: '24px',
                        '@media (max-width: 800px)': {
                            fontSize: '18px',
                        }
                    }}>ระบบจะพาไปยังหน้าเสียบบัตรภายใน {counter} วินาที</Box>
                </Box>
            </Box>
        </div>
    )
}