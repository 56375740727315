import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import GuideService from './page/GuideService';
import MainMenu from './page/MainMenu';
import CheckPermissions from './page/checkPermissions/CheckPermissions';
import AccessDenied from './page/AccessDenied';
import Consent from './page/ConSent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HealthHistory from './page/healthWithIoT/HealthHistory';
import MedicalInformationNew from './page/medicalInformation/MedicalInformationNew';
import OffSiteLab from './page/offSiteLabResults/OffSiteLab';
import { AuthWrapper, PublicRoute } from './page/AuthWrapper';
import HealthResultsAnnual from './page/healthExamResultsAnnual/HealthResultsAnnual';
import CheckForwarding from './page/checkForwarding/CheckForwarding';
import AppointmentList from './page/appointmentList/AppointmentList';
import Consult from './page/consult/Consult';
import Refer from './page/referDoc/Refer';
import LoginThaiD from './page/LoginThaiD';
import AuthLayout from './page/AuthLayout'
import ViewXrayhistory from './page/xray/ViewXrayhistory';

function App() {

  useEffect(() => {

  }, [])

  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>

          <Route element={<AuthLayout/>}>
            <Route path='/'
              element={
                <PublicRoute>
                  <GuideService />
                </PublicRoute>
              }
            />
            <Route path='/loginThaid' element={<LoginThaiD />} />
          </Route>


          <Route
            element={
              <AuthWrapper>
                <Outlet />
              </AuthWrapper>
            }
          >
            <Route path='/consent' element={<Consent />} />
            <Route path='/mainMenu' element={<MainMenu />} />
            <Route path='/accessDenied' element={<AccessDenied />} />

            <Route path='/medicalInformationNew' element={<MedicalInformationNew />} />

            <Route path='/checkPermissions' element={<CheckPermissions />} />

            <Route path='/healthHistory' element={<HealthHistory />} />

            <Route path='/offsiteLabResults' element={<OffSiteLab />} />

            <Route path='/healthResultsAnnual' element={<HealthResultsAnnual />} />

            <Route path='/checkForwarding' element={<CheckForwarding />} />

            <Route path='/appointmentList' element={<AppointmentList />} />

            <Route path='/consult' element={<Consult />} />

            <Route path='/refer' element={<Refer />} />

            <Route path='/xray' element={<ViewXrayhistory/>} />
          </Route>


        </Routes>
      </Router>
    </div>
  );
}

export default App;
