import React from 'react'
import { FadeLoader } from 'react-spinners';

export default function Loader({ loading, size, color }) {
    const loaderOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        display: loading ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999
    };

    const loaderStyle = {
        pointerEvents: 'none'
    };
    return (
        <div style={loaderOverlayStyle}>
            <div style={loaderStyle}>
                <FadeLoader loading={loading} size={size} color="#006838" />
            </div>
        </div>
    )
}