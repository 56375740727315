import axios from "axios";
import { environment } from '../config.env'

const ENDPOINT_DOMAIN = environment.endpoint_domain || ''
const domain = ENDPOINT_DOMAIN

// const ENDPOINT_DOMAIN_R12 = environment.endpoint_domain_r12 || ''
// const domain_r12 = ENDPOINT_DOMAIN_R12

const ENDPOINT_API = environment.endpoint_api || ''
const urlport = ENDPOINT_API

const ENDPOINT_API_WORKER = environment.endpoint_api_worker || ''
const domainWorker = ENDPOINT_API_WORKER




export function getQrCode() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/authen/digitalID/getQrCode`
        let header = {}

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function profile() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/digitalid/profile`
        let header = {
            'Platform': 'healthatm-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function refreshToken(refreshToken) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/digitalid/refreshToken`
        let body = {
            "RefreshToken": refreshToken
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}



export function findInformationNew(pid) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/medical/findInformation/healthatm`
        let body = {
            "pid": pid
        }
        let header = {
            'Platform': 'healthatm-dashboard',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformationIPD(pid, hos_id) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/medical/findInformation/healthatm`
        let body = {
            "collection": [
                "visit_information_ipd",
                "labs_information_ipd",
                "order_information_ipd",
                "diagnosis_information_ipd",
                "proced_information_ipd"
            ],
            "pid": pid,
            "hos_id": hos_id
        }
        let header = {
            'Platform': 'healthatm-dashboard',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function confirmConsent(pid, name) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/consent/card`
        let body = {
            "CID": pid,
            "Name": name,
            "Remark": "ข้าพเจ้ายินยอมให้บุคลากรทางการแพทย์เข้าถึงข้อมูลทางการแพทย์ได้ในภายใน 24 ชั่วโมงเท่านั้น",
            "Allow": [1]
        }
        let header = {
            'Platform': 'healthatm-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findHealthStationResult(PID) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/point-of-Care/findHealthStationResult?cid=${PID}&order=created_date&sort=desc`
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                // 'Platform': 'r12network-dashboard',
                'Platform': 'healthatm-dashboard',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        };

        axios.request(config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                resolve(false)
            });
    })
}

export function findBloodTestResult(pid) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/point-of-Care/findBloodTestResult?PID=${pid}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Platform': 'healthatm-dashboard'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getIndividualSummary(year) {  //ผลตวจสุขภาพประจำปี อาจจะต้องเปลี่ยน api
    return new Promise(resolve => {
        let url = `${urlport}/api/v1/public/individual/getIndividualSummary`
        let body = {
            "year": year
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findAppointment(pid) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/data/findAppointment?pid=${pid}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Platform': 'healthatm-dashboard',
            'Content-Type': 'application/json'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function createConferrenceRoom() {
    return new Promise(resolve => {
        let url = `https://microservice.numeddatacenter.com/api/microservice/v1/conferrence/createConferrenceRoom`
        let body = {}
        let header = {
            'Content-Type': 'application/json',
            'platform_type': 'R12Network-dashboard',
            'enviroment': environment.production ? 'PROD' : 'UAT',
            'Authorization': localStorage.getItem('access_token')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findDicomViewer() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/xray/findDicomViewer?cid=01234567891234`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Platform': 'healthatm-dashboard'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getHealthCheck(year) {
    return new Promise(resolve => {
        let url = `${domainWorker}/api/v2/thirdparty/health-atm/getHealthCheck`
        let body = {
            "year" : year
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        }
        axios.post(url,body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}